

@media (max-width: 450px) {
  .bg-body-tertiary {
    background: linear-gradient(to right, #fff 40%, #0d6efd 70%)
  }
  .card-img-top {
    height: 50px;
    width: 125px;
  }
}
@media (min-width: 450px) {
  .bg-body-tertiary {
    background: linear-gradient(to right, #fff 33%, #0d6efd 70%)
  }
  .card-img-top {
    height: 101px;
    width: 251px;
  }
}

.navbar-brand {
  color: black;
}

.row {
  padding-top: 20px;
}

.card {
  margin: 20px;
  min-height: 150px;
}

/*
.btn-primary {
  --bs-btn-bg: #2596be;
  --bs-btn-border-color: #2596be;
  --bs-btn-disabled-bg: #2596be;
  --bs-btn-disabled-border-color: #2596be;
}
*/

form {
  .form-group {
    margin-top: 15px;
  }
  
  .row {
    padding-top: 0px;
  }
}

.btn {
  margin-top: 5px;
}

@media (max-width: 450px) {
  .onRigthBottomCorner {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 10;
  }
  .spinner {
    position: fixed;
    top: 50vh;
    left: 50vw;
    z-index: 10;
    width: 60px; 
    height: 60px;
  }
}
@media (min-width: 450px) {
  .onRigthBottomCorner {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 10;
  }
  .spinner {
    position: fixed;
    top: 50vh;
    left: 50vw;
    z-index: 10;
    width: 90px; 
    height: 90px;
  }
}




